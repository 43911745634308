// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"

import CollapsibleTableController from "./collapsible_table_controller"
application.register("collapsible-table", CollapsibleTableController)

import HealthRecordsController from "./health_records_controller"
application.register("health-records", HealthRecordsController)

import AnswerFormController from "./answer_form_controller"
application.register("answer-form", AnswerFormController)

import UnauthorizedController from "./unauthorized_controller"
application.register("unauthorized", UnauthorizedController)

import HwComboboxController from "@josefarias/hotwire_combobox"
application.register("hw-combobox", HwComboboxController)

// import "@hotwired/turbo-rails"


// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)
